export default (ctx, inject) => {
  // make lazy component loaders available for hydratableLoader
  // lets set all component options of components which were detected by nuxt/components including its loaders as prop of each component

  // now we are going to inject all the dynamic webpack imports to each component as single property with its own chunk
  const loaders = {
      LazyNuxtDynamic: () => import('..\\node_modules\\@blokwise\\dynamic\\src\\components\\Dynamic.vue' /* webpackChunkName: "components/nuxt-dynamic" */).then(c => c.default || c),
      LazyLogo: () => import('..\\components\\Logo.vue' /* webpackChunkName: "components/logo" */).then(c => c.default || c),
      LazyVuetifyLogo: () => import('..\\components\\VuetifyLogo.vue' /* webpackChunkName: "components/vuetify-logo" */).then(c => c.default || c),
      LazyAdminSettingsAdmColumnsAutoComplete: () => import('..\\components\\admin-settings\\adm-columns-auto-complete.vue' /* webpackChunkName: "components/admin-settings-adm-columns-auto-complete" */).then(c => c.default || c),
      LazyAdminSettingsGlobalSettings: () => import('..\\components\\admin-settings\\global-settings.vue' /* webpackChunkName: "components/admin-settings-global-settings" */).then(c => c.default || c),
      LazyCatalogBreadcrumb: () => import('..\\components\\catalog\\catalog-breadcrumb.vue' /* webpackChunkName: "components/catalog-breadcrumb" */).then(c => c.default || c),
      LazyCatalogWishlist: () => import('..\\components\\catalog\\wishlist.vue' /* webpackChunkName: "components/catalog-wishlist" */).then(c => c.default || c),
      LazyCommonAlertDialog: () => import('..\\components\\common\\alert-dialog.vue' /* webpackChunkName: "components/common-alert-dialog" */).then(c => c.default || c),
      LazyCommonConfirm: () => import('..\\components\\common\\confirm.vue' /* webpackChunkName: "components/common-confirm" */).then(c => c.default || c),
      LazyCommonCurrencyInput: () => import('..\\components\\common\\currency-input.vue' /* webpackChunkName: "components/common-currency-input" */).then(c => c.default || c),
      LazyCommonCustomerButtons: () => import('..\\components\\common\\customer-buttons.vue' /* webpackChunkName: "components/common-customer-buttons" */).then(c => c.default || c),
      LazyCommonForgotPasswordDialog: () => import('..\\components\\common\\forgot-password-dialog.vue' /* webpackChunkName: "components/common-forgot-password-dialog" */).then(c => c.default || c),
      LazyCommonLeftMenu: () => import('..\\components\\common\\left-menu.vue' /* webpackChunkName: "components/common-left-menu" */).then(c => c.default || c),
      LazyCommonLoginCard: () => import('..\\components\\common\\login-card.vue' /* webpackChunkName: "components/common-login-card" */).then(c => c.default || c),
      LazyCommonNavigationBarsLogin: () => import('..\\components\\common\\navigation-bars-login.vue' /* webpackChunkName: "components/common-navigation-bars-login" */).then(c => c.default || c),
      LazyCommonNavigationBars: () => import('..\\components\\common\\navigation-bars.vue' /* webpackChunkName: "components/common-navigation-bars" */).then(c => c.default || c),
      LazyCommonPinLoginCard: () => import('..\\components\\common\\pin-login-card.vue' /* webpackChunkName: "components/common-pin-login-card" */).then(c => c.default || c),
      LazyCommonPinLoginKeyboard: () => import('..\\components\\common\\pin-login-keyboard.vue' /* webpackChunkName: "components/common-pin-login-keyboard" */).then(c => c.default || c),
      LazyCommonRegisterCard: () => import('..\\components\\common\\register-card.vue' /* webpackChunkName: "components/common-register-card" */).then(c => c.default || c),
      LazyCommonScanItemDialog: () => import('..\\components\\common\\scan-item-dialog.vue' /* webpackChunkName: "components/common-scan-item-dialog" */).then(c => c.default || c),
      LazyCommonSelectLocationDialog: () => import('..\\components\\common\\select-location-dialog.vue' /* webpackChunkName: "components/common-select-location-dialog" */).then(c => c.default || c),
      LazyCommonSwitchUser: () => import('..\\components\\common\\switch-user.vue' /* webpackChunkName: "components/common-switch-user" */).then(c => c.default || c),
      LazyDashboardSummaryTable: () => import('..\\components\\dashboard\\dashboard-summary-table.vue' /* webpackChunkName: "components/dashboard-summary-table" */).then(c => c.default || c),
      LazyDashboardTable: () => import('..\\components\\dashboard\\dashboard-table.vue' /* webpackChunkName: "components/dashboard-table" */).then(c => c.default || c),
      LazyDashboardDayPicker: () => import('..\\components\\dashboard\\day-picker.vue' /* webpackChunkName: "components/dashboard-day-picker" */).then(c => c.default || c),
      LazyDashboardFilterToggleButtons: () => import('..\\components\\dashboard\\filter-toggle-buttons.vue' /* webpackChunkName: "components/dashboard-filter-toggle-buttons" */).then(c => c.default || c),
      LazyDashboardGraphCard: () => import('..\\components\\dashboard\\graph-card.vue' /* webpackChunkName: "components/dashboard-graph-card" */).then(c => c.default || c),
      LazyDashboardMonthPicker: () => import('..\\components\\dashboard\\month-picker.vue' /* webpackChunkName: "components/dashboard-month-picker" */).then(c => c.default || c),
      LazyDashboardTableCard: () => import('..\\components\\dashboard\\table-card.vue' /* webpackChunkName: "components/dashboard-table-card" */).then(c => c.default || c),
      LazyIntegrationsSquareConfiguration: () => import('..\\components\\integrations\\square-configuration.vue' /* webpackChunkName: "components/integrations-square-configuration" */).then(c => c.default || c),
      LazyIntegrationsSquareConnectTerminal: () => import('..\\components\\integrations\\square-connect-terminal.vue' /* webpackChunkName: "components/integrations-square-connect-terminal" */).then(c => c.default || c),
      LazyIntegrationsSquareTerminals: () => import('..\\components\\integrations\\square-terminals.vue' /* webpackChunkName: "components/integrations-square-terminals" */).then(c => c.default || c),
      LazyNewSaleAddEditCustomerDialog: () => import('..\\components\\new-sale\\add-edit-customer-dialog.vue' /* webpackChunkName: "components/new-sale-add-edit-customer-dialog" */).then(c => c.default || c),
      LazyNewSaleCardPaymentDialog: () => import('..\\components\\new-sale\\card-payment-dialog.vue' /* webpackChunkName: "components/new-sale-card-payment-dialog" */).then(c => c.default || c),
      LazyNewSaleCartItemDetailDialog: () => import('..\\components\\new-sale\\cart-item-detail-dialog.vue' /* webpackChunkName: "components/new-sale-cart-item-detail-dialog" */).then(c => c.default || c),
      LazyNewSaleCheckoutDialog: () => import('..\\components\\new-sale\\checkout-dialog.vue' /* webpackChunkName: "components/new-sale-checkout-dialog" */).then(c => c.default || c),
      LazyNewSaleCustomerInfoDialog: () => import('..\\components\\new-sale\\customer-info-dialog.vue' /* webpackChunkName: "components/new-sale-customer-info-dialog" */).then(c => c.default || c),
      LazyNewSaleCustomerNoteDialog: () => import('..\\components\\new-sale\\customer-note-dialog.vue' /* webpackChunkName: "components/new-sale-customer-note-dialog" */).then(c => c.default || c),
      LazyNewSaleCustomerOrderHistory: () => import('..\\components\\new-sale\\customer-order-history.vue' /* webpackChunkName: "components/new-sale-customer-order-history" */).then(c => c.default || c),
      LazyNewSaleItemsToBuy: () => import('..\\components\\new-sale\\items-to-buy.vue' /* webpackChunkName: "components/new-sale-items-to-buy" */).then(c => c.default || c),
      LazyNewSalePaymentCard: () => import('..\\components\\new-sale\\payment-card.vue' /* webpackChunkName: "components/new-sale-payment-card" */).then(c => c.default || c),
      LazyNewSaleSelectCustomerDialog: () => import('..\\components\\new-sale\\select-customer-dialog.vue' /* webpackChunkName: "components/new-sale-select-customer-dialog" */).then(c => c.default || c),
      LazyNewSaleSummaryCalculations: () => import('..\\components\\new-sale\\summary-calculations.vue' /* webpackChunkName: "components/new-sale-summary-calculations" */).then(c => c.default || c),
      LazyNewSaleSummaryCard: () => import('..\\components\\new-sale\\summary-card.vue' /* webpackChunkName: "components/new-sale-summary-card" */).then(c => c.default || c),
      LazyOrdersOrderCard: () => import('..\\components\\orders\\order-card.vue' /* webpackChunkName: "components/orders-order-card" */).then(c => c.default || c),
      LazyOrdersOrderDetailDialog: () => import('..\\components\\orders\\order-detail-dialog.vue' /* webpackChunkName: "components/orders-order-detail-dialog" */).then(c => c.default || c),
      LazyOrdersOrderImagesDialog: () => import('..\\components\\orders\\order-images-dialog.vue' /* webpackChunkName: "components/orders-order-images-dialog" */).then(c => c.default || c),
      LazyOrdersOrderNoteDialog: () => import('..\\components\\orders\\order-note-dialog.vue' /* webpackChunkName: "components/orders-order-note-dialog" */).then(c => c.default || c),
      LazyOrdersOrderPaymentDialog: () => import('..\\components\\orders\\order-payment-dialog.vue' /* webpackChunkName: "components/orders-order-payment-dialog" */).then(c => c.default || c),
      LazyOrdersFilterCard: () => import('..\\components\\orders\\orders-filter-card.vue' /* webpackChunkName: "components/orders-filter-card" */).then(c => c.default || c),
      LazyProductsCustomItemDetail: () => import('..\\components\\products\\custom-item-detail.vue' /* webpackChunkName: "components/products-custom-item-detail" */).then(c => c.default || c),
      LazyProductsProductDetail: () => import('..\\components\\products\\product-detail.vue' /* webpackChunkName: "components/products-product-detail" */).then(c => c.default || c),
      LazyProductsProductImage: () => import('..\\components\\products\\product-image.vue' /* webpackChunkName: "components/products-product-image" */).then(c => c.default || c),
      LazyRegisterAdjustmentDialog: () => import('..\\components\\register\\adjustment-dialog.vue' /* webpackChunkName: "components/register-adjustment-dialog" */).then(c => c.default || c),
      LazyRegisterBalanceCard: () => import('..\\components\\register\\balance-card.vue' /* webpackChunkName: "components/register-balance-card" */).then(c => c.default || c),
      LazyRegisterFilterCardDatePicker: () => import('..\\components\\register\\filter-card-date-picker.vue' /* webpackChunkName: "components/register-filter-card-date-picker" */).then(c => c.default || c),
      LazyRegisterIconButton: () => import('..\\components\\register\\register-icon-button.vue' /* webpackChunkName: "components/register-icon-button" */).then(c => c.default || c),
      LazyRegisterTransactionsFilterCard: () => import('..\\components\\register\\register-transactions-filter-card.vue' /* webpackChunkName: "components/register-transactions-filter-card" */).then(c => c.default || c),
      LazyRegisterTransactions: () => import('..\\components\\register\\register-transactions.vue' /* webpackChunkName: "components/register-transactions" */).then(c => c.default || c),
      LazySharedCloseBtn: () => import('..\\components\\shared\\close-btn.vue' /* webpackChunkName: "components/shared-close-btn" */).then(c => c.default || c),
      LazySharedCustomButton: () => import('..\\components\\shared\\custom-button.vue' /* webpackChunkName: "components/shared-custom-button" */).then(c => c.default || c),
      LazySharedCustomCombo: () => import('..\\components\\shared\\custom-combo.vue' /* webpackChunkName: "components/shared-custom-combo" */).then(c => c.default || c),
      LazySharedDarkButton: () => import('..\\components\\shared\\dark-button.vue' /* webpackChunkName: "components/shared-dark-button" */).then(c => c.default || c),
      LazySharedFileUploader: () => import('..\\components\\shared\\file-uploader.vue' /* webpackChunkName: "components/shared-file-uploader" */).then(c => c.default || c),
      LazySharedFilterAutocompleteSelection: () => import('..\\components\\shared\\filter-autocomplete-selection.vue' /* webpackChunkName: "components/shared-filter-autocomplete-selection" */).then(c => c.default || c),
      LazySharedFilterCard: () => import('..\\components\\shared\\filter-card.vue' /* webpackChunkName: "components/shared-filter-card" */).then(c => c.default || c),
      LazySharedImageCropper: () => import('..\\components\\shared\\image-cropper.vue' /* webpackChunkName: "components/shared-image-cropper" */).then(c => c.default || c),
      LazySharedImageUploaderField: () => import('..\\components\\shared\\image-uploader-field.vue' /* webpackChunkName: "components/shared-image-uploader-field" */).then(c => c.default || c),
      LazySharedImageUploader: () => import('..\\components\\shared\\image-uploader.vue' /* webpackChunkName: "components/shared-image-uploader" */).then(c => c.default || c),
      LazySharedLabeledControlFields: () => import('..\\components\\shared\\labeled-control-fields.vue' /* webpackChunkName: "components/shared-labeled-control-fields" */).then(c => c.default || c),
      LazySharedLabeledControlLayout: () => import('..\\components\\shared\\labeled-control-layout.vue' /* webpackChunkName: "components/shared-labeled-control-layout" */).then(c => c.default || c),
      LazySharedNoteCardLayout: () => import('..\\components\\shared\\note-card-layout.vue' /* webpackChunkName: "components/shared-note-card-layout" */).then(c => c.default || c),
      LazySharedPaymentForm: () => import('..\\components\\shared\\payment-form.vue' /* webpackChunkName: "components/shared-payment-form" */).then(c => c.default || c),
      LazySharedPrimaryButton: () => import('..\\components\\shared\\primary-button.vue' /* webpackChunkName: "components/shared-primary-button" */).then(c => c.default || c),
      LazySharedPrimaryTitle: () => import('..\\components\\shared\\primary-title.vue' /* webpackChunkName: "components/shared-primary-title" */).then(c => c.default || c),
      LazySharedSecondaryButton: () => import('..\\components\\shared\\secondary-button.vue' /* webpackChunkName: "components/shared-secondary-button" */).then(c => c.default || c),
      LazySharedSimpleActionButton: () => import('..\\components\\shared\\simple-action-button.vue' /* webpackChunkName: "components/shared-simple-action-button" */).then(c => c.default || c),
      LazySharedSummaryScrollTable: () => import('..\\components\\shared\\summary-scroll-table.vue' /* webpackChunkName: "components/shared-summary-scroll-table" */).then(c => c.default || c),
      LazySharedSvgIconButton: () => import('..\\components\\shared\\svg-icon-button.vue' /* webpackChunkName: "components/shared-svg-icon-button" */).then(c => c.default || c),
      LazySharedTreeListRow: () => import('..\\components\\shared\\tree-list-row.vue' /* webpackChunkName: "components/shared-tree-list-row" */).then(c => c.default || c),
      LazyCommonMyProfileEditFormCard: () => import('..\\components\\common\\my-profile\\edit-form-card.vue' /* webpackChunkName: "components/common-my-profile-edit-form-card" */).then(c => c.default || c),
      LazyCommonMyProfileGeneralInfoCard: () => import('..\\components\\common\\my-profile\\general-info-card.vue' /* webpackChunkName: "components/common-my-profile-general-info-card" */).then(c => c.default || c),
      LazyCommonMyProfilePasswordChangeCard: () => import('..\\components\\common\\my-profile\\password-change-card.vue' /* webpackChunkName: "components/common-my-profile-password-change-card" */).then(c => c.default || c),
      LazyReportsCustomerBalancesScrollTable: () => import('..\\components\\reports\\customer-balances\\scroll-table.vue' /* webpackChunkName: "components/reports-customer-balances-scroll-table" */).then(c => c.default || c),
      LazyReportsSaleReportsCustomerSaleDialog: () => import('..\\components\\reports\\sale-reports\\customer-sale-dialog.vue' /* webpackChunkName: "components/reports-sale-reports-customer-sale-dialog" */).then(c => c.default || c),
      LazyReportsSaleReportsItemGroupDetailDialog: () => import('..\\components\\reports\\sale-reports\\item-group-detail-dialog.vue' /* webpackChunkName: "components/reports-sale-reports-item-group-detail-dialog" */).then(c => c.default || c),
      LazyReportsSaleReportsSalesByItems: () => import('..\\components\\reports\\sale-reports\\sales-by-items.vue' /* webpackChunkName: "components/reports-sale-reports-sales-by-items" */).then(c => c.default || c),
      LazyReportsSaleReportsSalesDetails: () => import('..\\components\\reports\\sale-reports\\sales-details.vue' /* webpackChunkName: "components/reports-sale-reports-sales-details" */).then(c => c.default || c),
      LazyReportsSaleReportsSummaryScrollTable: () => import('..\\components\\reports\\sale-reports\\summary-scroll-table.vue' /* webpackChunkName: "components/reports-sale-reports-summary-scroll-table" */).then(c => c.default || c)
  }

  const prefixes = ["frontend","nuxt"]

  inject("nuxtDynamic", { loaders, prefixes });
};
