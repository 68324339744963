const initState = () => ({
  location: {
    id: null,
    name: ''
  },
  switchedUser: false
})

export const state = initState

export const mutations = {
  setLocation(state, {location}) {
    state.location = location
  },
  setSwitchedUser(state, {value}){
    state.switchedUser = value
  }
}

export const actions = {
  setLocation({commit}, location) {
    commit('setLocation', {location: location })
  },
  resetLocation({commit}) {
    commit('setLocation', {location: initState() })
  }
}
