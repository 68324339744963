const initState = () => ({
})

export const state = initState

export const actions = {
  fetchDailyReport({commit}, {reportType, registerCode}) {
    return this.$axios.$get(`webpos/reports/print-daily-report`, {responseType: 'blob'})  },
}


