import {formActions} from "@/enums/formActions";
import {frontends} from "@/enums/frontends";

const initState = () => ({
  globalSettings: [],
})

export const state = initState

export const getters = {
  getGlobalSettings: (state) => (searchStr) => {
    const regexp = new RegExp(searchStr, 'i') // TODO add filtering back
    if (searchStr) {
      const result = []
      for (const group of state.globalSettings) {
        const adminSettings = group.adminSettings.filter(
          (x) => regexp.test(x.name) || regexp.test(x.code)
        )
        if (adminSettings.length > 0) {
          const returnedGroup = {...group}
          returnedGroup.adminSettings = adminSettings
          result.push(returnedGroup)
        }
      }
      return result
    }
    return state.globalSettings
  },
}

export const mutations = {
  setGlobalSettings(state, {globalSettings}) {
    state.globalSettings = globalSettings
  },
  setGlobalSettingFieldValue(state, {setting, value}) {
    setting.value = value
  },
}

export const actions = {
  async fetchGlobalSettings({commit, state, }, {}) {
    return await this.$axios
      .$get('/admin-settings/cp/settings', {
        params: {
          frontendId: frontends.WEB_POS
        }
      })
      .then((result) => {
        commit('setGlobalSettings', {globalSettings: result})
      })
  },
  updateGlobalSettingFieldValue({commit, dispatch}, {setting, value}) {
    this.$catch(
      async () => {
        await this.$axios.$put(`/admin-settings/cp/settings/${setting.code}`, {
          value,
        })
        commit('setGlobalSettingFieldValue', {setting, value})
        dispatch('settings/fetchAdminSettings', null, {root: true})
        this.$toast.success(
          this.$i18n.t('saved_successfully').replace('{name}', setting.name)
        )
      },
      formActions.Save,
      setting.name
    )
  },
}
