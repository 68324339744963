import {formActions} from '@/enums/formActions'

const initState = () => ({
  customerBalances: [],
  saleReports: [],
  salesByItemsReports: []
})

export const state = initState

export const getters = {
}

export const mutations = {
  setCustomerBalances(state, {reports}){
    state.customerBalances = reports
  },
  addCustomerBalances(state,{reports}){
    reports.forEach(r => state.customerBalances.items.push(r))
  },
  resetCustomerBalances(state){
    state.customerBalances.items = []
    state.customerBalances.itemCount = 0
  },

  setSalesByItemsReports(state, {reports}){
    state.salesByItemsReports = reports
  },
  addSalesByItemsReports(state,{reports}){
    reports.forEach(r => state.salesByItemsReports.push(r))
  },

  setSaleReports(state, {reports}){
    state.saleReports = reports
  },
  addSaleReports(state,{reports}){
    reports.forEach(r => state.saleReports.push(r))
  },
}

export const actions = {
  async getCustomerBalances({commit, dispatch}, {filters, pageNo, pageSize}){
    return new Promise((resolve, reject) => {
      this.$catch(
        async () => {
          let url = `/webpos/reports/customerBalances`
          let filter = {}
          filters.map((f) => {
            if (f.value) {
              if(Array.isArray(f.value)){
                Object.assign(filter, {[f.property]: f.value.toString()})
              }
              else{
                Object.assign(filter, {[f.property]: f.value})
              }
            }
          })
          Object.assign(filter, {pageNo: pageNo})
          Object.assign(filter, {pageSize: pageSize})


          let reports = await this.$axios.$post(url,filter)
          reports = await dispatch('mapCustomerBalances', {response: reports})
          commit('setCustomerBalances', {reports: reports})
          resolve(reports)
        },
        formActions.Load,
        'reports'
      )
    })
  },
  getSaleReports({commit, dispatch}, {filters, pageNo, pageSize})  {
    return new Promise((resolve, reject) => {
      this.$catch(
        async () => {
          let url = `/webpos/reports/sale-reports`
          let filter = {}
          filters.map((f) => {
            if (f.value) {
              if(Array.isArray(f.value)){
                Object.assign(filter, {[f.property]: f.value.toString()})
              }
              else{
                Object.assign(filter, {[f.property]: f.value})
              }
            }
          })
          Object.assign(filter, {pageNo: pageNo})
          Object.assign(filter, {pageSize: pageSize})
          let reports = await this.$axios.$post(url,filter)

          reports = await dispatch('mapSaleReports', {response: reports})
          commit('setSaleReports', {reports: reports})
          resolve(reports)
        },
        formActions.Load,
        'reports'
      )
    })
  },
  async getSalesByItemsReports({commit, dispatch}, {filters, pageNo, pageSize})  {
    return new Promise((resolve, reject) => {
      this.$catch(
        async () => {
          let url = `/webpos/reports/sales-by-items-reports`
          let filter = {}
          filters.map((f) => {
            if (f.value) {
              if(Array.isArray(f.value)){
                Object.assign(filter, {[f.property]: f.value.toString()})
              }
              else{
                Object.assign(filter, {[f.property]: f.value})
              }
            }
          })
          Object.assign(filter, {pageNo: pageNo})
          Object.assign(filter, {pageSize: pageSize})
          let reports = await this.$axios.$post(url,filter)

          reports = await dispatch('mapSalesByItemsReports', {response: reports})
          commit('setSalesByItemsReports', {reports: reports})
          resolve(reports)

        },
        formActions.Load,
        'reports'
      )
    })
  },



  async mapCustomerBalances({commit},{response}){
    let reportResponse = response.data ? response.data : response
    let reports = {
      items: [],
      itemCount: null
    }
    reportResponse.items.map(x => {
     let customerBalance =  {
        id: x.id,
        customerName: x.customerName ? x.customerName : x.companyName,
        emailAddress: x.emailAddress,
        phoneNumber: x.phoneNumber,
        totalValue: x.totalValue,
        balance: x.balance,
        paid: x.paid
      }
      reports.items.push(customerBalance)
    })
    reports.itemCount = reportResponse.pagingData.itemCount
    return reports
  },

  async mapSaleReports({commit},{response}){
    let reports = []
    response.value.map(x => {
      if (this.$auth.user.userGroups.find(x => x.groupCode === "WEB_POS_ADMIN") != null){
        reports.push({
          customerId: x.customerID,
          customerName: x.companyName ? x.companyName : x.firstName + ' ' + x.lastName,
          totalItemsSold: x.totalItemsSold,
          salesAmount: x.salesAmount,
          balance: x.balance,
          salesPersonName: x.salesPersonName
        })
      }
      else {
        reports.push({
          customerId: x.customerID,
          customerName: x.companyName ? x.companyName : x.firstName + ' ' + x.lastName,
          totalItemsSold: x.totalItemsSold,
          salesAmount: x.salesAmount,
          balance: x.balance
        })
      }

    })
    return reports
  },
  async mapSalesByItemsReports({commit},{response}){
    let reports = []
    response.value.map(x => {
      reports.push({
        itemGroupId: x.itemGroupID,
        itemGroupName: x.itemGroupName,
        itemsSold: x.itemsSold,
        totalSalesForGroup: x.totalSalesForGroup,

      })
    })
    return reports
  }
}
