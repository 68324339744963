const initState = () => ({
	companyLocations: [],
})

export const state = initState

export const getters = {
	getCompanyLocations: (state) => () => {
		return state.companyLocations
	}
}

export const mutations = {
	setCompanyLocations(state, {companyLocations}) {
		state.companyLocations = companyLocations
	},
  resetCompanyLocations(state){
    state.companyLocations = []
  }
}

export const actions = {
  async fetchCompanyLocations({commit}) {
    const result = await this.$axios.$get('user/stores')

    if (result && result.value) {
      commit('setCompanyLocations', {
        companyLocations: result.value.map((x) => {
          return {
            id: x.value,
            name: x.name
          }
        })
      })
    }

    return result;
  },
  async checkCompanyLocationsByUsername({commit}, username) {
      const result = await this.$axios.$get('user/stores-by-user-name/'+ username)

      return result && result.length > 0
  }
}
