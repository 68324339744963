const initState = () => ({
  orders: {
    new: {
      total: 0,
      items: [],
      options: {},
    },
    processing: {
      total: 0,
      items: [],
      options: {},
    },
    closed: {
      total: 0,
      items: [],
      options: {},
    },
    all: {
      total: 0,
      items: [],
      options: {},
    },
  },
  orderNotes: [],
  page: 1,
  totalOrders: 0,
  options: {},
  jobOrderItems: [],
  jobImages: [],
  orderItemImages: [],
})

export const state = initState

export const getters = {
  getServiceById: (state) => (id) =>
    state.jobServices.find((item) => item.id === id),
}

export const mutations = {
  setOrderNotes(state, {notes}) {
    state.orderNotes = notes
  },
  setJobImages(state, {jobImages}) {
    state.jobImages = jobImages
  },
  updateNoteCount(state, {jobID})
  {
    let objIndex;
    objIndex = state.orders.new.items.findIndex((obj => obj.jobID === jobID));
    if(objIndex>=0)
      state.orders.new.items[objIndex].noteCount++

    objIndex = state.orders.processing.items.findIndex((obj => obj.jobID === jobID));
    if(objIndex>=0)
      state.orders.processing.items[objIndex].noteCount++

    objIndex = state.orders.closed.items.findIndex((obj => obj.jobID === jobID));
    if(objIndex>=0)
      state.orders.closed.items[objIndex].noteCount++

    objIndex = state.orders.all.items.findIndex((obj => obj.jobID === jobID));
    if(objIndex>=0)
      state.orders.closed.all[objIndex].noteCount++
  },
  setOrderItemImages(state, {images}) {
    state.orderItemImages = images
  },
}

export const actions = {
  async fetchOrderNotes({commit, state}, {jobID}) {
    const result = await this.$axios.$get(`/JobOrders/${jobID}/notes`)
    if (result) {
      commit('setOrderNotes', {notes: result})
    }
    return result
  },

  async saveOrderNote({commit, state}, {note, image, jobID}) {
    const formData = new FormData()
    formData.append('image', image)
    formData.append('note', note)
    formData.append('jobOrderID', jobID)
    commit('updateNoteCount',{jobID})
    return await this.$axios.$post(`/JobOrders/notes`, formData)

  },
  async fetchJobImages({commit, state}, {orderId}) {
    return await this.$axios
      .$get('/JobOrders/' + orderId + '/images')
      .then((result) => {
        commit('setJobImages', {jobImages: result})
      })
  },

  async fetchOrderItemImages({commit, state}, {jobID}) {
    const result = await this.$axios.$get(`/webpos/order/images/${jobID}`)
    if (result && !result.hasError) {
      commit('setOrderItemImages', {images: result.value})
      return result.value
    }
    else{
      this.$toast.error(result.errorMessages[0])
    }
  },
  removeImages({commit, state}){
    commit('setOrderItemImages', {images: []})
  }

}
