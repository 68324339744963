const initState = () => ({
  configuration: null,
})

export const state = initState

export const mutations = {
  setConfiguration(state, {configuration}) {
    state.configuration = configuration.value
  }
}

export const actions = {
  async fetchConfiguration({commit}) {
    const configuration = await this.$axios.$get('/webpos/payments/get-square-configuration')
    if (configuration && configuration.value) {
      commit('setConfiguration', {configuration})

      return configuration.value
    }
  },
  async obtainToken({commit}, tokenRequest) {
    try {
      return await this.$axios.$post('/webpos/payments/obtain-square-token', tokenRequest)
    }
    catch (ex) {
      if (ex.response && ex.response.data) {
        return ex.response.data
      }
    }
  },
  async connectTerminal({commit}, accessToken) {
    try {
      return await this.$axios.$post('/webpos/payments/connect-square-terminal', {accessToken: accessToken})
    }
    catch (ex) {
      if (ex.response && ex.response.data) {
        return ex.response.data
      }
    }
  },
  async getTerminals({commit}, accessToken) {
    try {
      return await this.$axios.$post('/webpos/payments/get-terminals', {accessToken: accessToken})
    }
    catch (ex) {
      if (ex.response && ex.response.data) {
        return ex.response.data
      }
    }
  },
  async addPayment({commit}, paymentData) {
    try {
      return await this.$axios.$post('/webpos/payments/add-payment', paymentData)
    }
    catch (ex) {
      if (ex.response && ex.response.data) {
        return ex.response.data
      }
    }
  },
  async insertSquareCard({commit},{card, customerID} ){
    return await this.$axios
      .$post(`webpos/credit-card/insert-square-card/${customerID}`, card)
  },
  async tokenize({commit},{paymentMethod}) {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === 'OK') {
      return tokenResult.token;
    } else {
      let errorMessage = `Tokenization failed-status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(
          tokenResult.errors
        )}`;
      }
      throw new Error(errorMessage);
    }
  },
}
