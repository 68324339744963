import {formActions} from '@/enums/formActions'

const initState = () => ({
  customer:{
  },
  customerSettings:{}
})

export const state = initState

export const getters = {
}

export const mutations = {
  setCustomerDatas(state, {customer}) {
    if(customer){
      state.customer = customer;
    }
    else{
      state.customer = {}
    }
  },
  unselectCustomer(state) {
    state.customer = {}
  },
  incCustomerWishlistItemCount(state){
    state.customer.wishlistItemCount += 1
  },
  descCustomerWishlistItemCount(state){
    state.customer.wishlistItemCount -= 1
  },
  setCustomerSettings(state,{customerSettings}){
    state.customerSettings = customerSettings
  }
}

export const actions = {
  async updateCustomer({commit}, {customer}) {
    await this.$catch(
      async () => {
        await this.$axios.$put(`webpos/customer/updateCustomerContactDatas/${customer.id}`, {
          id: customer.id,
          customerUSID:  customer.usID,
          companyName: customer.customerIsCompany ? customer.firstName : null,
          firstName:  customer.customerIsCompany ? null : customer.firstName,
          lastName: customer.customerIsCompany ? null : customer.lastName,
          contactFirstName: customer.customerIsCompany ? customer.contactFirstName : null,
          contactLastName: customer.customerIsCompany ? customer.contactFirstName : null,
          email: customer.email,
          phone: customer.phone,
          contactFullName: (customer.contactFirstName !== null && customer.contactFirstName !== "") ?`${customer.contactFirstName} ${customer.contactLastName}` : null,
          addressID: customer.addressID,
          address: (customer.addressID !== null && customer.addressID !== "") ? {
            id: customer.addressID,
            addressLine1: customer.address1,
            addressLine2: customer.address2,
            countryID: customer.countryID,
            stateID: customer.stateID,
            city: customer.city,
            zip: customer.zip
          } : null,
        }).then((result) => {
          if(!result.hasErrors){
            this.$toast.success("Customer updated successfully");
          }
          else{
            this.$toast.error(result.errorMessages[0].value);
          }

        }).catch((result) => {
          this.$toast.error(result.message);
        });
      },
      formActions.Save
    );
  },

  saveCustomer({commit, dispatch}, customerId){
    this.$catch(
      async () => {
        let result = await this.$axios.$get(`webpos/customer/getCustomerContactData/${customerId}`, {
          id: customerId,
        });

        if (!result.hasErrors) {
          let apiCustomer = await dispatch('convertCustomer', {customer: result.value, address: result.value.address})
          apiCustomer.wishlistItemCount = result.value.wishlistItemCount > 0 ? result.value.wishlistItemCount : 0
          commit('setCustomerDatas', {customer: apiCustomer});
        } else {
          this.$toast.error('Error while selecting the customer');
        }
      });
  },

  async getCustomersDataForFilter({commit}) {
    return await this.$axios.$get('webpos/customer/').then((result) => {
      return result.value.map((customer) => {
        return {
          value: customer.id,
          text: (customer.companyName != null && customer.companyName !== "") ? customer.companyName : `${customer.firstName} ${customer.lastName}`,
        }
      }).sort((a, b) => a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1)
    })
  },
  async getVendorsForFilter({commit}) {
    return await this.$axios.$get('webpos/customer/vendors').then((result) => {
      return result.value.map((customer) => {
        return {
          value: customer.id,
          text: (customer.companyName != null && customer.companyName !== "") ? customer.companyName : `${customer.firstName} ${customer.lastName}`,
        }
      }).sort((a, b) => a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1)
    })
  },
  async insertNotes({commit}, {customerId, notes}){
    await this.$axios.$post(`webpos/customer/insert-notes/${customerId}`,
      notes
    ).then((result) => {
      if (!result.hasErrors){
        this.$toast.success("Customer notes inserted successfully");
      }
      else {
        this.$toast.error('Error while inserting notes for customer');
      }
    })
  },
  async getCustomerContactData({commit, dispatch}, customerId){
    let result = await this.$axios.$get(`webpos/customer/getCustomerContactData/${customerId}`)
    if(!result.hasErrors) {
      return await dispatch('convertCustomer', {customer: result.value, address: result.value.address })
    }
    else {
      this.$toast.error('Error while selecting the customer!');
      return null;
    }
  },
  async getCustomerSettings({commit}){
    let result = await this.$axios.$get(`webpos/customer/get-customer-settings`)
    if (result.hasErrors){
      this.$toast.error("Error while getting customer settings");
    }
    else
    {
      commit('setCustomerSettings', {customerSettings: result.value});
    }
  },
  async convertCustomer({commit}, {customer, address}){
    return {
      id: customer.id,
      usID: customer.customerUSID,
      name: customer.companyName || `${customer.firstName} ${customer.lastName}`,
      firstName:  customer.companyName || customer.firstName,
      lastName: customer.lastName || '',
      email: customer.email,
      phone:  customer.phone,
      contactFirstName: (customer.companyName && customer.contactFullName.split(' ').length > 1) ? customer.contactFullName.split(' ')[0] : '',
      contactLastName:  (customer.companyName && customer.contactFullName.split(' ').length > 1) ? customer.contactFullName.split(' ')[1] : '',
      addressID: customer.address && customer.addressID || '',
      address: address && address.addressName || '',
      address1: address && address.addressLine1 || '',
      address2: address && address.addressLine2 || '',
      countryID: address && address.countryID || '',
      stateID: address && address.stateID || '',
      country: address && address.country || '',
      city: address && address.city || '',
      state: address && address.state || '',
      zip: address && address.zip || '',
      wishlistItemCount: 0,
      companyName: customer.companyName
    }
  }
}
