const initState = () => ({
  total : 0,
  paymentMethod: '',
  salesTax: 0
})

export const state = initState

export const getters = {
  getTotal: (state) => state.total,
  getPaymentMethod: (state) => state.paymentMethod,
}

export const setters ={
}

export const mutations = {
  setTotal(state, {total}){
    state.total = total
  },
  setPaymentMethod(state,{paymentMethod}){
    state.paymentMethod = paymentMethod
  },
  setSalesTax(state, {salesTax}) {
    state.salesTax = salesTax
  }
}

export const actions = {
  async createWebPOSOrder({commit}, {orderData}) {
    return await this.$axios.$post(`/pos/order/createorder`,orderData)
  },
  async getSalesTax({commit},customerId){
    const salesTax = await this.$axios.$get(
      '/webpos/shoppingCart/get-sales-tax/' + customerId

    )

    commit('setSalesTax', {salesTax: salesTax.value})
    return salesTax
  },
  async getPaymentTypes({commit}){
    return  await this.$axios.$get(
      '/paymenttypes/'
    )
  }
}

