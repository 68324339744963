const initState = () => ({
  transactions: [],
})

export const state = initState

export const getters = {
  getTransactions(state) {
    return state.transactions
  }
}


export const mutations = {
  setTransactions(state, {transactions}) {
    state.transactions = transactions
  },
}

export const actions = {
  async fetchTransactions({commit}, {filters, page}) {
    let pageNumber= page ? page : 1
    filters = filters ? filters : {
      formDate: null,
      toDate: null,
      saleNumber: undefined,
    }
       return await this.$axios.$post('pos/transactions/' + 50 + '/' + pageNumber, {
        createdWhen: filters.fromDate,
        dateTo: filters.toDate,
        salesOrderCode: filters.saleNumber  ? filters.saleNumber : null
      }).then((transactions)=>{
        commit('setTransactions', {transactions})
      })

  },


}
