import Vue from 'vue'

const components = {
  NuxtDynamic: () => import('../..\\node_modules\\@blokwise\\dynamic\\src\\components\\Dynamic.vue' /* webpackChunkName: "components/nuxt-dynamic" */).then(c => c.default || c),
  Logo: () => import('../..\\components\\Logo.vue' /* webpackChunkName: "components/logo" */).then(c => c.default || c),
  VuetifyLogo: () => import('../..\\components\\VuetifyLogo.vue' /* webpackChunkName: "components/vuetify-logo" */).then(c => c.default || c),
  AdminSettingsAdmColumnsAutoComplete: () => import('../..\\components\\admin-settings\\adm-columns-auto-complete.vue' /* webpackChunkName: "components/admin-settings-adm-columns-auto-complete" */).then(c => c.default || c),
  AdminSettingsGlobalSettings: () => import('../..\\components\\admin-settings\\global-settings.vue' /* webpackChunkName: "components/admin-settings-global-settings" */).then(c => c.default || c),
  CatalogBreadcrumb: () => import('../..\\components\\catalog\\catalog-breadcrumb.vue' /* webpackChunkName: "components/catalog-breadcrumb" */).then(c => c.default || c),
  CatalogWishlist: () => import('../..\\components\\catalog\\wishlist.vue' /* webpackChunkName: "components/catalog-wishlist" */).then(c => c.default || c),
  CommonAlertDialog: () => import('../..\\components\\common\\alert-dialog.vue' /* webpackChunkName: "components/common-alert-dialog" */).then(c => c.default || c),
  CommonConfirm: () => import('../..\\components\\common\\confirm.vue' /* webpackChunkName: "components/common-confirm" */).then(c => c.default || c),
  CommonCurrencyInput: () => import('../..\\components\\common\\currency-input.vue' /* webpackChunkName: "components/common-currency-input" */).then(c => c.default || c),
  CommonCustomerButtons: () => import('../..\\components\\common\\customer-buttons.vue' /* webpackChunkName: "components/common-customer-buttons" */).then(c => c.default || c),
  CommonForgotPasswordDialog: () => import('../..\\components\\common\\forgot-password-dialog.vue' /* webpackChunkName: "components/common-forgot-password-dialog" */).then(c => c.default || c),
  CommonLeftMenu: () => import('../..\\components\\common\\left-menu.vue' /* webpackChunkName: "components/common-left-menu" */).then(c => c.default || c),
  CommonLoginCard: () => import('../..\\components\\common\\login-card.vue' /* webpackChunkName: "components/common-login-card" */).then(c => c.default || c),
  CommonNavigationBarsLogin: () => import('../..\\components\\common\\navigation-bars-login.vue' /* webpackChunkName: "components/common-navigation-bars-login" */).then(c => c.default || c),
  CommonNavigationBars: () => import('../..\\components\\common\\navigation-bars.vue' /* webpackChunkName: "components/common-navigation-bars" */).then(c => c.default || c),
  CommonPinLoginCard: () => import('../..\\components\\common\\pin-login-card.vue' /* webpackChunkName: "components/common-pin-login-card" */).then(c => c.default || c),
  CommonPinLoginKeyboard: () => import('../..\\components\\common\\pin-login-keyboard.vue' /* webpackChunkName: "components/common-pin-login-keyboard" */).then(c => c.default || c),
  CommonRegisterCard: () => import('../..\\components\\common\\register-card.vue' /* webpackChunkName: "components/common-register-card" */).then(c => c.default || c),
  CommonScanItemDialog: () => import('../..\\components\\common\\scan-item-dialog.vue' /* webpackChunkName: "components/common-scan-item-dialog" */).then(c => c.default || c),
  CommonSelectLocationDialog: () => import('../..\\components\\common\\select-location-dialog.vue' /* webpackChunkName: "components/common-select-location-dialog" */).then(c => c.default || c),
  CommonSwitchUser: () => import('../..\\components\\common\\switch-user.vue' /* webpackChunkName: "components/common-switch-user" */).then(c => c.default || c),
  DashboardSummaryTable: () => import('../..\\components\\dashboard\\dashboard-summary-table.vue' /* webpackChunkName: "components/dashboard-summary-table" */).then(c => c.default || c),
  DashboardTable: () => import('../..\\components\\dashboard\\dashboard-table.vue' /* webpackChunkName: "components/dashboard-table" */).then(c => c.default || c),
  DashboardDayPicker: () => import('../..\\components\\dashboard\\day-picker.vue' /* webpackChunkName: "components/dashboard-day-picker" */).then(c => c.default || c),
  DashboardFilterToggleButtons: () => import('../..\\components\\dashboard\\filter-toggle-buttons.vue' /* webpackChunkName: "components/dashboard-filter-toggle-buttons" */).then(c => c.default || c),
  DashboardGraphCard: () => import('../..\\components\\dashboard\\graph-card.vue' /* webpackChunkName: "components/dashboard-graph-card" */).then(c => c.default || c),
  DashboardMonthPicker: () => import('../..\\components\\dashboard\\month-picker.vue' /* webpackChunkName: "components/dashboard-month-picker" */).then(c => c.default || c),
  DashboardTableCard: () => import('../..\\components\\dashboard\\table-card.vue' /* webpackChunkName: "components/dashboard-table-card" */).then(c => c.default || c),
  IntegrationsSquareConfiguration: () => import('../..\\components\\integrations\\square-configuration.vue' /* webpackChunkName: "components/integrations-square-configuration" */).then(c => c.default || c),
  IntegrationsSquareConnectTerminal: () => import('../..\\components\\integrations\\square-connect-terminal.vue' /* webpackChunkName: "components/integrations-square-connect-terminal" */).then(c => c.default || c),
  IntegrationsSquareTerminals: () => import('../..\\components\\integrations\\square-terminals.vue' /* webpackChunkName: "components/integrations-square-terminals" */).then(c => c.default || c),
  NewSaleAddEditCustomerDialog: () => import('../..\\components\\new-sale\\add-edit-customer-dialog.vue' /* webpackChunkName: "components/new-sale-add-edit-customer-dialog" */).then(c => c.default || c),
  NewSaleCardPaymentDialog: () => import('../..\\components\\new-sale\\card-payment-dialog.vue' /* webpackChunkName: "components/new-sale-card-payment-dialog" */).then(c => c.default || c),
  NewSaleCartItemDetailDialog: () => import('../..\\components\\new-sale\\cart-item-detail-dialog.vue' /* webpackChunkName: "components/new-sale-cart-item-detail-dialog" */).then(c => c.default || c),
  NewSaleCheckoutDialog: () => import('../..\\components\\new-sale\\checkout-dialog.vue' /* webpackChunkName: "components/new-sale-checkout-dialog" */).then(c => c.default || c),
  NewSaleCustomerInfoDialog: () => import('../..\\components\\new-sale\\customer-info-dialog.vue' /* webpackChunkName: "components/new-sale-customer-info-dialog" */).then(c => c.default || c),
  NewSaleCustomerNoteDialog: () => import('../..\\components\\new-sale\\customer-note-dialog.vue' /* webpackChunkName: "components/new-sale-customer-note-dialog" */).then(c => c.default || c),
  NewSaleCustomerOrderHistory: () => import('../..\\components\\new-sale\\customer-order-history.vue' /* webpackChunkName: "components/new-sale-customer-order-history" */).then(c => c.default || c),
  NewSaleItemsToBuy: () => import('../..\\components\\new-sale\\items-to-buy.vue' /* webpackChunkName: "components/new-sale-items-to-buy" */).then(c => c.default || c),
  NewSalePaymentCard: () => import('../..\\components\\new-sale\\payment-card.vue' /* webpackChunkName: "components/new-sale-payment-card" */).then(c => c.default || c),
  NewSaleSelectCustomerDialog: () => import('../..\\components\\new-sale\\select-customer-dialog.vue' /* webpackChunkName: "components/new-sale-select-customer-dialog" */).then(c => c.default || c),
  NewSaleSummaryCalculations: () => import('../..\\components\\new-sale\\summary-calculations.vue' /* webpackChunkName: "components/new-sale-summary-calculations" */).then(c => c.default || c),
  NewSaleSummaryCard: () => import('../..\\components\\new-sale\\summary-card.vue' /* webpackChunkName: "components/new-sale-summary-card" */).then(c => c.default || c),
  OrdersOrderCard: () => import('../..\\components\\orders\\order-card.vue' /* webpackChunkName: "components/orders-order-card" */).then(c => c.default || c),
  OrdersOrderDetailDialog: () => import('../..\\components\\orders\\order-detail-dialog.vue' /* webpackChunkName: "components/orders-order-detail-dialog" */).then(c => c.default || c),
  OrdersOrderImagesDialog: () => import('../..\\components\\orders\\order-images-dialog.vue' /* webpackChunkName: "components/orders-order-images-dialog" */).then(c => c.default || c),
  OrdersOrderNoteDialog: () => import('../..\\components\\orders\\order-note-dialog.vue' /* webpackChunkName: "components/orders-order-note-dialog" */).then(c => c.default || c),
  OrdersOrderPaymentDialog: () => import('../..\\components\\orders\\order-payment-dialog.vue' /* webpackChunkName: "components/orders-order-payment-dialog" */).then(c => c.default || c),
  OrdersFilterCard: () => import('../..\\components\\orders\\orders-filter-card.vue' /* webpackChunkName: "components/orders-filter-card" */).then(c => c.default || c),
  ProductsCustomItemDetail: () => import('../..\\components\\products\\custom-item-detail.vue' /* webpackChunkName: "components/products-custom-item-detail" */).then(c => c.default || c),
  ProductsProductDetail: () => import('../..\\components\\products\\product-detail.vue' /* webpackChunkName: "components/products-product-detail" */).then(c => c.default || c),
  ProductsProductImage: () => import('../..\\components\\products\\product-image.vue' /* webpackChunkName: "components/products-product-image" */).then(c => c.default || c),
  RegisterAdjustmentDialog: () => import('../..\\components\\register\\adjustment-dialog.vue' /* webpackChunkName: "components/register-adjustment-dialog" */).then(c => c.default || c),
  RegisterBalanceCard: () => import('../..\\components\\register\\balance-card.vue' /* webpackChunkName: "components/register-balance-card" */).then(c => c.default || c),
  RegisterFilterCardDatePicker: () => import('../..\\components\\register\\filter-card-date-picker.vue' /* webpackChunkName: "components/register-filter-card-date-picker" */).then(c => c.default || c),
  RegisterIconButton: () => import('../..\\components\\register\\register-icon-button.vue' /* webpackChunkName: "components/register-icon-button" */).then(c => c.default || c),
  RegisterTransactionsFilterCard: () => import('../..\\components\\register\\register-transactions-filter-card.vue' /* webpackChunkName: "components/register-transactions-filter-card" */).then(c => c.default || c),
  RegisterTransactions: () => import('../..\\components\\register\\register-transactions.vue' /* webpackChunkName: "components/register-transactions" */).then(c => c.default || c),
  SharedCloseBtn: () => import('../..\\components\\shared\\close-btn.vue' /* webpackChunkName: "components/shared-close-btn" */).then(c => c.default || c),
  SharedCustomButton: () => import('../..\\components\\shared\\custom-button.vue' /* webpackChunkName: "components/shared-custom-button" */).then(c => c.default || c),
  SharedCustomCombo: () => import('../..\\components\\shared\\custom-combo.vue' /* webpackChunkName: "components/shared-custom-combo" */).then(c => c.default || c),
  SharedDarkButton: () => import('../..\\components\\shared\\dark-button.vue' /* webpackChunkName: "components/shared-dark-button" */).then(c => c.default || c),
  SharedFileUploader: () => import('../..\\components\\shared\\file-uploader.vue' /* webpackChunkName: "components/shared-file-uploader" */).then(c => c.default || c),
  SharedFilterAutocompleteSelection: () => import('../..\\components\\shared\\filter-autocomplete-selection.vue' /* webpackChunkName: "components/shared-filter-autocomplete-selection" */).then(c => c.default || c),
  SharedFilterCard: () => import('../..\\components\\shared\\filter-card.vue' /* webpackChunkName: "components/shared-filter-card" */).then(c => c.default || c),
  SharedImageCropper: () => import('../..\\components\\shared\\image-cropper.vue' /* webpackChunkName: "components/shared-image-cropper" */).then(c => c.default || c),
  SharedImageUploaderField: () => import('../..\\components\\shared\\image-uploader-field.vue' /* webpackChunkName: "components/shared-image-uploader-field" */).then(c => c.default || c),
  SharedImageUploader: () => import('../..\\components\\shared\\image-uploader.vue' /* webpackChunkName: "components/shared-image-uploader" */).then(c => c.default || c),
  SharedLabeledControlFields: () => import('../..\\components\\shared\\labeled-control-fields.vue' /* webpackChunkName: "components/shared-labeled-control-fields" */).then(c => c.default || c),
  SharedLabeledControlLayout: () => import('../..\\components\\shared\\labeled-control-layout.vue' /* webpackChunkName: "components/shared-labeled-control-layout" */).then(c => c.default || c),
  SharedNoteCardLayout: () => import('../..\\components\\shared\\note-card-layout.vue' /* webpackChunkName: "components/shared-note-card-layout" */).then(c => c.default || c),
  SharedPaymentForm: () => import('../..\\components\\shared\\payment-form.vue' /* webpackChunkName: "components/shared-payment-form" */).then(c => c.default || c),
  SharedPrimaryButton: () => import('../..\\components\\shared\\primary-button.vue' /* webpackChunkName: "components/shared-primary-button" */).then(c => c.default || c),
  SharedPrimaryTitle: () => import('../..\\components\\shared\\primary-title.vue' /* webpackChunkName: "components/shared-primary-title" */).then(c => c.default || c),
  SharedSecondaryButton: () => import('../..\\components\\shared\\secondary-button.vue' /* webpackChunkName: "components/shared-secondary-button" */).then(c => c.default || c),
  SharedSimpleActionButton: () => import('../..\\components\\shared\\simple-action-button.vue' /* webpackChunkName: "components/shared-simple-action-button" */).then(c => c.default || c),
  SharedSummaryScrollTable: () => import('../..\\components\\shared\\summary-scroll-table.vue' /* webpackChunkName: "components/shared-summary-scroll-table" */).then(c => c.default || c),
  SharedSvgIconButton: () => import('../..\\components\\shared\\svg-icon-button.vue' /* webpackChunkName: "components/shared-svg-icon-button" */).then(c => c.default || c),
  SharedTreeListRow: () => import('../..\\components\\shared\\tree-list-row.vue' /* webpackChunkName: "components/shared-tree-list-row" */).then(c => c.default || c),
  CommonMyProfileEditFormCard: () => import('../..\\components\\common\\my-profile\\edit-form-card.vue' /* webpackChunkName: "components/common-my-profile-edit-form-card" */).then(c => c.default || c),
  CommonMyProfileGeneralInfoCard: () => import('../..\\components\\common\\my-profile\\general-info-card.vue' /* webpackChunkName: "components/common-my-profile-general-info-card" */).then(c => c.default || c),
  CommonMyProfilePasswordChangeCard: () => import('../..\\components\\common\\my-profile\\password-change-card.vue' /* webpackChunkName: "components/common-my-profile-password-change-card" */).then(c => c.default || c),
  ReportsCustomerBalancesScrollTable: () => import('../..\\components\\reports\\customer-balances\\scroll-table.vue' /* webpackChunkName: "components/reports-customer-balances-scroll-table" */).then(c => c.default || c),
  ReportsSaleReportsCustomerSaleDialog: () => import('../..\\components\\reports\\sale-reports\\customer-sale-dialog.vue' /* webpackChunkName: "components/reports-sale-reports-customer-sale-dialog" */).then(c => c.default || c),
  ReportsSaleReportsItemGroupDetailDialog: () => import('../..\\components\\reports\\sale-reports\\item-group-detail-dialog.vue' /* webpackChunkName: "components/reports-sale-reports-item-group-detail-dialog" */).then(c => c.default || c),
  ReportsSaleReportsSalesByItems: () => import('../..\\components\\reports\\sale-reports\\sales-by-items.vue' /* webpackChunkName: "components/reports-sale-reports-sales-by-items" */).then(c => c.default || c),
  ReportsSaleReportsSalesDetails: () => import('../..\\components\\reports\\sale-reports\\sales-details.vue' /* webpackChunkName: "components/reports-sale-reports-sales-details" */).then(c => c.default || c),
  ReportsSaleReportsSummaryScrollTable: () => import('../..\\components\\reports\\sale-reports\\summary-scroll-table.vue' /* webpackChunkName: "components/reports-sale-reports-summary-scroll-table" */).then(c => c.default || c)
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
