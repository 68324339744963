const initState = () => ({
  register: null,
})

export const state = initState
export const getters = {
}

export const mutations = {
  setRegisterCode(state, {register}) {
    state.register = register
  },
  reset(state) {
    Object.assign(state, initState())
  },
}

export const actions = {
  async openclose({commit}, {register}) {
      await this.$axios.$post(`/webpos/registers/openclose`, register).then((register)=>{
        commit('setRegisterCode',{register: register.value})
      })
  },
  async registerOperation({commit}, {registerOperation}) {
    console.log("ide")
      await this.$axios.$post(`/webpos/registers/operations`, registerOperation)
  },
  async getRegister({commit}, {imei}) {
    await this.$axios.$post(`/webpos/registers/devices/${imei}`).then((register) =>{
      commit('setRegisterCode',{register: register.value})
    })
  },

  async getDailyPaymentTotal() {
    let response = await this.$axios.$get(`/webpos/registers/get-daily-operations-total`);
    console.log(response)
    if(response && response.errorMessages !== null ){
      this.$toast.error(response.errorMessages[0])
    }
    else{
      return response.value;
    }
  },

}
