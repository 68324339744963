import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _9f9615a2 = () => interopDefault(import('..\\pages\\catalog\\index.vue' /* webpackChunkName: "pages/catalog/index" */))
const _fb1d36a8 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))
const _4c1907c3 = () => interopDefault(import('..\\pages\\login.vue' /* webpackChunkName: "pages/login" */))
const _f7ce0dc0 = () => interopDefault(import('..\\pages\\logout.vue' /* webpackChunkName: "pages/logout" */))
const _118502a4 = () => interopDefault(import('..\\pages\\my-profile\\index.vue' /* webpackChunkName: "pages/my-profile/index" */))
const _685fa2be = () => interopDefault(import('..\\pages\\newsale\\index.vue' /* webpackChunkName: "pages/newsale/index" */))
const _17413327 = () => interopDefault(import('..\\pages\\orders\\index.vue' /* webpackChunkName: "pages/orders/index" */))
const _555afd42 = () => interopDefault(import('..\\pages\\payments\\index.vue' /* webpackChunkName: "pages/payments/index" */))
const _f6ed87ae = () => interopDefault(import('..\\pages\\register\\index.vue' /* webpackChunkName: "pages/register/index" */))
const _2b369fc9 = () => interopDefault(import('..\\pages\\reports\\index.vue' /* webpackChunkName: "pages/reports/index" */))
const _728f4bc7 = () => interopDefault(import('..\\pages\\wishlist\\index.vue' /* webpackChunkName: "pages/wishlist/index" */))
const _c2ed529e = () => interopDefault(import('..\\pages\\configuration\\admin-settings\\index.vue' /* webpackChunkName: "pages/configuration/admin-settings/index" */))
const _39ccd5b3 = () => interopDefault(import('..\\pages\\configuration\\integrations\\index.vue' /* webpackChunkName: "pages/configuration/integrations/index" */))
const _259565f4 = () => interopDefault(import('..\\pages\\register\\transactions\\index.vue' /* webpackChunkName: "pages/register/transactions/index" */))
const _da8dfec6 = () => interopDefault(import('..\\pages\\reports\\customer-balances.vue' /* webpackChunkName: "pages/reports/customer-balances" */))
const _2356d3bb = () => interopDefault(import('..\\pages\\reports\\customer-statements-dialog.vue' /* webpackChunkName: "pages/reports/customer-statements-dialog" */))
const _3c5e3612 = () => interopDefault(import('..\\pages\\reports\\sale-reports.vue' /* webpackChunkName: "pages/reports/sale-reports" */))
const _e1fc36b2 = () => interopDefault(import('..\\pages\\catalog\\_id\\products\\index.vue' /* webpackChunkName: "pages/catalog/_id/products/index" */))
const _26a24189 = () => interopDefault(import('..\\pages\\product\\_id\\_slug\\index.vue' /* webpackChunkName: "pages/product/_id/_slug/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/catalog",
    component: _9f9615a2,
    name: "catalog___en"
  }, {
    path: "/fr",
    component: _fb1d36a8,
    name: "index___fr"
  }, {
    path: "/login",
    component: _4c1907c3,
    name: "login___en"
  }, {
    path: "/logout",
    component: _f7ce0dc0,
    name: "logout___en"
  }, {
    path: "/my-profile",
    component: _118502a4,
    name: "my-profile___en"
  }, {
    path: "/newsale",
    component: _685fa2be,
    name: "newsale___en"
  }, {
    path: "/orders",
    component: _17413327,
    name: "orders___en"
  }, {
    path: "/payments",
    component: _555afd42,
    name: "payments___en"
  }, {
    path: "/register",
    component: _f6ed87ae,
    name: "register___en"
  }, {
    path: "/reports",
    component: _2b369fc9,
    name: "reports___en"
  }, {
    path: "/wishlist",
    component: _728f4bc7,
    name: "wishlist___en"
  }, {
    path: "/configuration/admin-settings",
    component: _c2ed529e,
    name: "configuration-admin-settings___en"
  }, {
    path: "/configuration/integrations",
    component: _39ccd5b3,
    name: "configuration-integrations___en"
  }, {
    path: "/fr/catalog",
    component: _9f9615a2,
    name: "catalog___fr"
  }, {
    path: "/fr/login",
    component: _4c1907c3,
    name: "login___fr"
  }, {
    path: "/fr/logout",
    component: _f7ce0dc0,
    name: "logout___fr"
  }, {
    path: "/fr/my-profile",
    component: _118502a4,
    name: "my-profile___fr"
  }, {
    path: "/fr/newsale",
    component: _685fa2be,
    name: "newsale___fr"
  }, {
    path: "/fr/orders",
    component: _17413327,
    name: "orders___fr"
  }, {
    path: "/fr/payments",
    component: _555afd42,
    name: "payments___fr"
  }, {
    path: "/fr/register",
    component: _f6ed87ae,
    name: "register___fr"
  }, {
    path: "/fr/reports",
    component: _2b369fc9,
    name: "reports___fr"
  }, {
    path: "/fr/wishlist",
    component: _728f4bc7,
    name: "wishlist___fr"
  }, {
    path: "/register/transactions",
    component: _259565f4,
    name: "register-transactions___en"
  }, {
    path: "/reports/customer-balances",
    component: _da8dfec6,
    name: "reports-customer-balances___en"
  }, {
    path: "/reports/customer-statements-dialog",
    component: _2356d3bb,
    name: "reports-customer-statements-dialog___en"
  }, {
    path: "/reports/sale-reports",
    component: _3c5e3612,
    name: "reports-sale-reports___en"
  }, {
    path: "/fr/configuration/admin-settings",
    component: _c2ed529e,
    name: "configuration-admin-settings___fr"
  }, {
    path: "/fr/configuration/integrations",
    component: _39ccd5b3,
    name: "configuration-integrations___fr"
  }, {
    path: "/fr/register/transactions",
    component: _259565f4,
    name: "register-transactions___fr"
  }, {
    path: "/fr/reports/customer-balances",
    component: _da8dfec6,
    name: "reports-customer-balances___fr"
  }, {
    path: "/fr/reports/customer-statements-dialog",
    component: _2356d3bb,
    name: "reports-customer-statements-dialog___fr"
  }, {
    path: "/fr/reports/sale-reports",
    component: _3c5e3612,
    name: "reports-sale-reports___fr"
  }, {
    path: "/fr/catalog/:id/products",
    component: _e1fc36b2,
    name: "catalog-id-products___fr"
  }, {
    path: "/fr/product/:id?/:slug",
    component: _26a24189,
    name: "product-id-slug___fr"
  }, {
    path: "/catalog/:id/products",
    component: _e1fc36b2,
    name: "catalog-id-products___en"
  }, {
    path: "/product/:id?/:slug",
    component: _26a24189,
    name: "product-id-slug___en"
  }, {
    path: "/",
    component: _fb1d36a8,
    name: "index___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config.app && config.app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
