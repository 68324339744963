import {PIROEntityTypes} from '@/enums/PIROEntityTypes'
import {formActions} from '@/enums/formActions'

const initState = () => ({
  showFilter: false,
  product: {},
  products: [],
  customProduct:{},
  customProducts: [],
  attributes: [],
})

export const state = initState

export const getters = {

}

export const mutations = {

  setProducts(state, {products}) {
    state.products = products
  },
  setProduct(state, {product}){
    state.product = product
  },
  setCustomProducts(state, {products}) {
    state.customProducts = products
  },
  setAttributes(state, {attributes}){
    let attributesWithValues = attributes.map((attr) => {
      let value = `${attr.valueStr} ${attr.measureUnitSymbol || ''}`.trim()
      if (value){
        return {
          id: attr.id,
          code: attr.code,
          name: attr.name,
          value: value,
          file: {
            fileUrl: attr.imageUrl,
          }
      }
      }
    })

    state.attributes = attributesWithValues.filter(a => a != null)

  },

}

export const actions = {
  async getProducts({commit}, {id, pagingInfo}) {
    commit('setProducts', {products: []})
    const products = await this.$axios.$get(
      `webpos/products?categoryId=${id}`+
      `&pageNo=${pagingInfo.pageNo}&pageSize=${pagingInfo.pageSize}`
    )
    commit('setProducts', {products})
    return products
  },
  async getFilteredProducts({commit}, {filters, pagingInfo}){
    return new Promise((resolve, reject)=>{
      this.$catch(
        async () => {
          let url = `webpos/products?categoryId=${filters.id}`+
            `&code=`+ (filters.code ? filters.code : '')  +
            `&pageNo=${pagingInfo.pageNo}&pageSize=${pagingInfo.pageSize}`
          filters.appliedFilters.forEach(f =>{
            if(f.value){
              url = url + `&${f.property}=${f.value}`
            }
          })
          const filteredProducts = await this.$axios.$get(
            url
          )
            resolve({filteredProducts})

          commit('setProducts',{products: filteredProducts})
        },
        formActions.Load,
        'filteredProducts',
      )
    })

  },
  async getProductById({commit}, productId) {
    const product = await this.$axios.$get(
      `webpos/products/${productId}`
    )
    commit('setProduct', {product})
  },
  async fetchProductAttributes({commit}, {itemTypeID,entityID, showInProductCatalog}) {
    const attributes = await this.$axios.$get(
      `webpos/products/${itemTypeID}/${entityID}/customattributevalues`
    )
    if (showInProductCatalog) {
      commit('setAttributes', {
        attributes: attributes.filter(
          (x) => x.showInProductCatalog === showInProductCatalog
        ),
      })
    } else {
      commit('setAttributes', {attributes})
    }
  },
  async getCustomizableProducts({commit}, {id, pagingInfo}) {
    commit('setCustomProducts', {products: []})
    const products = await this.$axios.$get(
      `webpos/products/customize?categoryId=${id}`+
      `&pageNo=${pagingInfo.pageNo}&pageSize=${pagingInfo.pageSize}`
    )
    commit('setCustomProducts', {products})
    return products
  },
  async getFilteredCustomizableProducts({commit}, {filters, pagingInfo}){
    return new Promise((resolve, reject)=>{
      this.$catch(
        async () => {
          let url = `webpos/products/customize?categoryId=${filters.id}`+
            `&code=`+ (filters.code ? filters.code : '')  +
            `&pageNo=${pagingInfo.pageNo}&pageSize=${pagingInfo.pageSize}`

          filters.appliedFilters.forEach(f =>{
            if(f.value){
              url = url + `&${f.property}=${f.value}`
            }
          })

          const filteredProducts = await this.$axios.$get(
            url
          )
          resolve({filteredProducts})

          commit('setCustomProducts',{products: filteredProducts})
        },
        formActions.Load,
        'filteredProducts',
      )
    })

  },
}
