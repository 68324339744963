import {formActions} from "@/enums/formActions";

const initState = () => ({
  creditCards: [],
  payments: {
    items: [],
    itemCount: null
  },
})

export const state = initState

export const mutations = {
  setCreditCards(state,{creditCards}){
    state.creditCards = creditCards
  },
  setPayments(state, {payments}) {
    state.payments = payments
  }
}

export const actions = {
  async postPayment({commit}, {paymentData}) {
    const paymentResult = await this.$axios.$post(`webpos/payments/save-payment`, paymentData)
    return paymentResult
  },
  fetchCreditCards({commit}, customerID) {
      return this.$axios.$get(`/webpos/credit-card/${customerID}`).then((creditCards) => {
        commit('setCreditCards', {creditCards})
        return creditCards
      })
  },
  async getPayments({commit, dispatch}, {filters, pageNo, pageSize}) {
    return new Promise((resolve, reject) => {
      this.$catch(
        async () => {
          let url = `/webpos/payments/get-payments`
          let filter = {}
          filters.map((f) => {
            if (f.value) {
              if(Array.isArray(f.value)){
                Object.assign(filter, {[f.property]: f.value.toString()})
              }
              else{
                Object.assign(filter, {[f.property]: f.value})
              }
            }
          })
          Object.assign(filter, {pageNo: pageNo})
          Object.assign(filter, {pageSize: pageSize})
          let response = await this.$axios.$post(url,filter)

          let payments = await dispatch('mapPayments', {response: response})
          commit('setPayments', {payments: payments})
          resolve(payments)
        },
        formActions.Load,
        'payments'
      )
    })
  },
    mapPayments({commit},{response}){
      let payments = {
        items: [],
        itemCount: null
      }
      response.items.map(payment => {
        let newPayment = {
          code: payment.paymentCode,
          method: payment.method,
          date: payment.date,
          customerId: payment.customerID,
          customerName: payment.customerName,
          invoiceCode: payment.invoiceCode,
          orderId: payment.orderID,
          orderCode: payment.orderCode,
          paymentAmount: payment.paymentAmount

        }
        payments.items.push(newPayment)
      })
      payments.itemCount = response.pagingData.itemCount
      return payments
  },

}
