import {formActions} from '@/enums/formActions'

const initState = () => ({
  itemGroups: [],
})

export const state = initState

export const getters = {
  getItemGroups: (state) => state.itemGroups,
}

export const mutations = {
  setItemGroups(state, {itemGroups}) {
    state.itemGroups = itemGroups
  },
}

export const actions = {
  async fetchItemGroups({commit}) {
    return await this.$axios.$get('webpos/itemgroup/').then((result) => {
      return result.map((element) => {
        return {text: element.n, value: element.id}
      })
    })
  },
}
